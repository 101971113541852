import React, { useEffect, useState } from "react";

function generatePagination(currentPage, totalPages, visiblePages) {
    const pagination = [];
  
    if (totalPages <= visiblePages) {
      pagination.push(...Array.from({ length: totalPages }, (_, i) => i + 1));
    } else {
      const halfVisiblePages = Math.floor(visiblePages / 2);
  
      if (currentPage <= halfVisiblePages + 1) {
        pagination.push(...Array.from({ length: visiblePages - 1 }, (_, i) => i + 1));
        pagination.push("...");
        pagination.push(totalPages);
      } else if (currentPage >= totalPages - halfVisiblePages) {
        pagination.push(1);
        pagination.push("...");
        pagination.push(...Array.from({ length: visiblePages - 1 }, (_, i) => totalPages - visiblePages + i + 2));
      } else {
        pagination.push(1);
        pagination.push("...");
        pagination.push(...Array.from({ length: visiblePages - 2 }, (_, i) => currentPage - halfVisiblePages + i));
        pagination.push("...");
        pagination.push(totalPages);
      }
    }
  
    return pagination;
}
  

function Pagination({
    pageNumber,
    setPageNumber,
    itemsCount, 
    itemsPerPage,
    onPageChange
}){

    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(1)
    const visiblePages = 5;

    useEffect(() => {
        let pageCount = Math.ceil(itemsCount / itemsPerPage);
        
        setPages(generatePagination(1, pageCount, visiblePages));
        setPageCount(pageCount);
    }, [itemsCount]);

    const changePage = (pageNum) => {
        setPageNumber(pageNum);
        onPageChange(pageNum);
        setPages(generatePagination(pageNum, pageCount, visiblePages));
    }

    return (
        <>
            <div className="flex flex-col items-center my-12">
                <div className="flex text-gray-300">
                    <button 
                        className="h-12 w-12 mr-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
                        disabled={pageNumber-1 > 0? false: true}
                        onClick={() => changePage(pageNumber-1)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="#555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left w-6 h-6">
                            <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                    </button>
                    <div className="flex h-12 font-medium rounded-full bg-[#36d2cd]/[.06]">
                        {
                            pages.map((v,i) => (
                                v == pageNumber? (
                                        <div 
                                            key={i}
                                            className="w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full bg-[#36d2cd] text-white"
                                            onClick={() => changePage(v)}
                                        >{v}</div>
                                    ): (

                                        v == "..."? (
                                            <div 
                                                key={i}
                                                className="w-12 md:flex justify-center items-center hidden leading-5 transition duration-150 ease-in  rounded-full"
                                            >{v}</div>
                                        ):(
                                            <div 
                                                key={i}
                                                className="w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full"
                                                onClick={() => changePage(v)}    
                                            >{v}</div>
                                        )
                                        
                                    )
                            ))
                        }
                        
                        <div className="w-12 h-12 md:hidden flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-[#36d2cd] text-white">{pageNumber}</div>
                    </div>
                    <button
                        className="h-12 w-12 ml-1 flex justify-center items-center rounded-full bg-gray-200 cursor-pointer"
                        disabled={pageNumber+1 <= pageCount? false: true}
                        onClick={() => changePage(pageNumber+1)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="#555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right w-6 h-6">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </button>
                </div>
            </div>
        </>
    )

}

export {
    Pagination
}