function VolumeHeader({
    totalVolumeETH,
    totalVolumeUSD
}){
    return (
        <div className="flex flex-col md:flex-row justify-between mb-3 md:mb-4 mt-2">
            <div className="w-full md:w-[40%] lg:w-[35%] border border-[#2ba8a4]/80 p-3 diagnol-bg">
            <div className="w-full flex text-white items-center">
                <div className="w-[30%] flex items-center justify-center">
                    <img className="h-[50px]" src="/eth-logo.png" alt="eth logo" />
                </div>
                <div className="w-[70%] text-xl md:text-3xl text-right">
                    { totalVolumeETH } ETH
                </div>
            </div>
            </div>
            <div className="w-full md:w-[40%] lg:w-[35%] border border-[#2ba8a4]/80 p-3 mt-3 md:mt-0 diagnol-bg">
                <div className="w-full flex text-white items-center">
                    <div className="w-[30%] flex items-center justify-center">
                        <h1 className="text-4xl text-gray-500">$</h1>
                    </div>
                    <div className="w-[70%] text-xl md:text-3xl text-right">
                        { totalVolumeUSD } USD
                    </div>
                </div>
            </div>
        </div>
    )
}

export {
    VolumeHeader
};