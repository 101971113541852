import React, { useState, useRef, useEffect } from "react";
import { animated, useSpring } from '@react-spring/web';
import { ethers } from "ethers";
import clsx from 'clsx';
import { provider } from "../helpers";


function LeaderboardTXTableItem({
    txHash,
    amountETH,
    amountUSD,
    blockNumber
}){ 

    const [timestamp, setTimestamp] = useState(null);

    const getTimestamp = async (blockNumber) => {
      const timestamp = (await provider.getBlock(blockNumber)).timestamp;
      return timestamp;
    }

    const formatTimestamp = (ts) => {
      let date = new Date(timestamp * 1000);
      return date.toLocaleString();
    }

    useEffect(() => {
      (async function x() {
          setTimestamp(await getTimestamp(blockNumber));
      })();
    }, []);

    return (
      <tr>
        <td className="px-3 py-4 text-xs text-neutral-400 whitespace-nowrap">
          <a
            href={`https://etherscan.io/tx/${txHash}`}
            className="underline hover:text-white"
            target={'_blank'}
            rel={'norefferer'}
          >
            {txHash.slice(0, 6)}...{txHash.slice(-4)}
          </a>
        </td>
        <td className="px-3 py-4 text-xs text-neutral-400 whitespace-nowrap">
          {amountETH.toFixed(1)} ETH
        </td>
        <td className="px-3 py-4 text-xs text-neutral-400 whitespace-nowrap">
          ${
              parseInt(amountUSD.toFixed(1))
              .toLocaleString() 
            }  USD
        </td>
        <td className="px-3 py-4 text-xs text-neutral-400 whitespace-nowrap">
          { formatTimestamp(timestamp) }
        </td>
      </tr>
    );
  }

function LeaderboardItem({
    rank,
    id,
    amountETH,
    amountUSD,
    transactions
}){
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const ref = useRef(null);
    const [style, animate] = useSpring(() => ({ height: '0px', opacity: 0 }), []);
    useEffect(() => {
        animate({
        height: (isOpen ? ref?.current?.offsetHeight : 0) + 'px',
        opacity: isOpen ? 100 : 0,
        });
    }, [animate, ref, isOpen]);

    return (
        <>
          <tr
            key={`${id}`}
            className={clsx(
              'cursor-pointer hover:bg-black/25',
              isOpen && 'bg-black/25'
            )}
            onClick={() => toggle()}
          >
            <td className="py-4 pl-4 pr-3 text-sm font-bold text-gray-300 whitespace-nowrap">
              #{rank}
            </td>
            <td className="relative px-3 py-4 text-sm text-gray-300 whitespace-nowrap">
              <a
                href={`https://etherscan.io/address/${id}`}
                className="relative z-0 font-bold underline hover:text-white"
                target={'_blank'}
                rel={'norefferer'}
              >
                {id.slice(0, 6)}...{id.slice(-4)}
              </a>
            </td>
            <td className="px-3 py-4 text-sm text-gray-300 whitespace-nowrap">
              {
                parseFloat(
                  ethers.formatEther(
                    BigInt(amountETH)
                  )
                ).toFixed(1)
              } ETH
            </td>
            <td className="px-3 py-4 text-sm text-gray-300 whitespace-nowrap">
              ${
                parseInt(amountUSD.toFixed(1))
                .toLocaleString() 
              } USD
            </td>
          </tr>
    
          <animated.tr
            className={clsx(
              'h-0 overflow-hidden bg-black/25',
              isOpen ? 'visible' : 'collapse'
            )}
            style={{
              ...style,
            }}
          >
            <td colSpan={4} className="w-full h-full px-4 align-baseline">
              <div ref={ref} className="w-full h-full">
                <p className="text-sm font-bold text-white">Last {transactions.length > 5? 5: transactions.length} Deposits</p>
                <table className="min-w-full divide-y divide-[#36d2cd] table-fixed ">
                  <thead className='space-grotesk'>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-xs text-neutral-200 sm:pl-0"
                      >
                        TX
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs text-neutral-200"
                      >
                        Amount ETH
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs text-neutral-200"
                      >
                        Amount USD
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs text-neutral-200"
                      >
                        Timestamp
                      </th>
                    </tr>
                  </thead>
                  <tbody className='space-grotesk'>
                    {transactions.slice(0, 5).map((deposit, idx) => (
                      <LeaderboardTXTableItem
                        key={idx}
                        amountETH={deposit.amountETH}
                        amountUSD={deposit.amountUSD}
                        txHash={deposit.txHash}
                        blockNumber={deposit.blockNumber}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </animated.tr>
        </>
      );
}

export {
    LeaderboardItem
}