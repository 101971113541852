import React, { useState, useEffect } from "react";
import LogoSVG from "../components/logo";
import { getDepositData } from "../helpers";
import { LeaderboardItem } from "../components/leaderboard";
import { Pagination } from "../components/pagnation";
import { Wallet } from "../components/wallet";
import { VolumeHeader } from "../components/volumeHeader";
import { useAccount } from 'wagmi';
import { ethers } from 'ethers';

function Home() {

    const [depositItems, setDepositItems] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const itemsPerPage = 20;

    const [pageOffset, setPageOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [myResult, setMyResult] = useState({});
    const [queryResults, setQueryResults] = useState({
      totalVolumeETH: 0,
      totalVolumeUSD: 0,
      volumeETH: 0,
      volumeUSD: 0,
      loaded: false
    })
    const { address, isConnected } = useAccount();


    useEffect(() => {
      if(depositItems.length < 1){
        (async function x() {
            let result = await getDepositData();
            setDepositItems(result.txData);
            setQueryResults(result);
            setPageCount(Math.ceil(result.txData.length / itemsPerPage));
        })();
      }
    }, []);

    useEffect(() => {
        if (isConnected) {
            setMyResult(depositItems.filter((result) => result.sender === address)[0]);
        }
    }, [isConnected, address, depositItems]);

    const handlePageChange = (pageNum) => {
        const newOffset = ((pageNum-1) * itemsPerPage) % depositItems.length;
        setPageOffset(newOffset);
    };

    return (
        <div
          className={`relative w-screen bg-cover bg-center bg-no-repeat`}
          style={{ backgroundImage: 'url(/bg1.png)', backgroundAttachment: 'fixed' }}
        >

        <div 
          className="w-full absolute top-10 z-10 transform-gpu blur-3xl left-0 lg:top-[calc(50%-45rem)]" 
        >
          <div 
            className="aspect-[1108/632] w-full bg-gradient-to-r from-[#36d2cd] to-[#afedeb] opacity-50 md:opacity-20" 
            style={{ clipPath: "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" }}
          ></div>
        </div>

          <div className="p-4 pt-12 max-w-[100%] lg:max-w-[80%] mx-auto flow-root">
            <LogoSVG className={'w-32'} />
          </div>
    
          <div className={`flow-root p-4 mt-2 w-full lg:max-w-[80%] mx-auto relative z-20`}>
            <h1 className='space-grotesk'>
              <span className="text-4xl font-bold text-neutral-200">
                Leaderboard
              </span>
            </h1>
            <p className='mt-2 text-xs md:text-sm text-neutral-400 space-mono'>
              The total volume of ETH deposited since genesis.
            </p>
            <VolumeHeader
              totalVolumeETH={
                parseFloat(
                  ethers.formatEther(BigInt(queryResults.totalVolumeETH).toString())
                  ).toFixed(1)
              }
              totalVolumeUSD={
                parseInt(parseFloat(queryResults.totalVolumeUSD)
                    .toFixed(1))
                    .toLocaleString() 
              }
            />

            <p className='mt-2 text-xs md:text-sm text-neutral-400 space-mono'>
              The total volume of ETH deposits since the start of the contest.
            </p>
            <VolumeHeader
              totalVolumeETH={
                parseFloat(
                  ethers.formatEther(BigInt(queryResults.volumeETH || 0).toString())
                  ).toFixed(1)
              }
              totalVolumeUSD={
                parseInt(parseFloat(queryResults.volumeUSD || 0)
                    .toFixed(1))
                    .toLocaleString() 
              }
            />
            <p className='mt-2 text-xs md:text-sm text-neutral-400 space-mono'>
              The top addresses with the highest amount of ETH deposited in participation for the contest.
            </p>
            <Wallet myResult={myResult} />
            <div className="mt-2 overflow-x-auto border border-neutral-700/80">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 bg-neutral-900 max-h-[80%] overflow-auto">
                <table className="min-w-full divide-y divide-[#36d2cd] table-fixed ">
                  <thead className='space-grotesk'>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left font-bold text-gray-200 sm:pl-0 text-sm md:text-base whitespace-nowrap"
                      >
                        Rank
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left font-bold text-gray-200 text-sm md:text-base whitespace-nowrap"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left font-bold text-gray-200 text-sm md:text-base whitespace-nowrap"
                      >
                        Amount ETH
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left font-bold text-gray-200 text-sm md:text-base whitespace-nowrap"
                      >
                        Amount USD
                      </th>
                    </tr>
                  </thead>
                  <tbody className='space-mono'>
                  { (queryResults.loaded? (
                      depositItems.length > 0? (
                          depositItems.slice(pageOffset, pageOffset+itemsPerPage).map((r) => (
                            <LeaderboardItem key={r.id} {...r} />
                          ))
                      ): (
                        <></>
                      )
                    ) : (<></>))
                    
                    
                  }
                  </tbody>
                </table>
                
                {!queryResults.loaded? (
                    [...Array(itemsPerPage)].map((elementInArray, index) => ( 
                        <div key={index} className="w-full animated-background py-5 mb-2">
                        </div>
                      ) 
                    )
                  ): (
                    depositItems.length <= 1? (
                      <h3 className="text-center my-5 py-5">No Transactions Yet!</h3>
                    ):(<></>)
                  )}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center pb-12">
            <Pagination 
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              itemsCount={depositItems.length}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      );
  }
  
  export { Home };