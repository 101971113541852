import { ethers } from "ethers";
import { supabase } from "./database";

const poolAddress = "0x3d18AD735f949fEbD59BBfcB5864ee0157607616";
const rpcURL = `https://eth-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY}`;
const tokenPriceURL = `https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${process.env.REACT_APP_ETHERSCAN_KEY}`

const provider = new ethers.JsonRpcProvider(rpcURL);

const abi = [
      {
          "anonymous": false,
          "inputs": [
              {
                  "indexed": false,
                  "internalType": "address",
                  "name": "",
                  "type": "address"
              },
              {
                  "indexed": false,
                  "internalType": "uint256",
                  "name": "tokenAmount",
                  "type": "uint256"
              },
              {
                  "indexed": false,
                  "internalType": "uint256",
                  "name": "ringIndex",
                  "type": "uint256"
              }
          ],
          "name": "Deposit",
          "type": "event"
      },
      {
          "anonymous": false,
          "inputs": [
              {
                  "indexed": false,
                  "internalType": "address",
                  "name": "",
                  "type": "address"
              },
              {
                  "indexed": false,
                  "internalType": "uint256",
                  "name": "tokenAmount",
                  "type": "uint256"
              },
              {
                  "indexed": false,
                  "internalType": "uint256",
                  "name": "ringIndex",
                  "type": "uint256"
              }
          ],
          "name": "Withdraw",
          "type": "event"
      }
  ];

const privkey = process.env.REACT_APP_PRIVATE_KEY;
const wallet = new ethers.Wallet(privkey, provider);
const signer = wallet.connect(provider);

const contract = new ethers.Contract(
    poolAddress,
    abi,
    signer
);

async function getNativeTokenPrice(){
  let data = JSON.parse(await fetch(
      tokenPriceURL,
      { 
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      }
  ).then((res) => res.text()));
  let usdValue = 0;

  if(data["message"] === "OK"){
      usdValue = parseFloat(data["result"]["ethusd"]);
  }
  return usdValue;
}

async function _getDepositData() {
  const genesisBlock = 17366599;
  const fromBlock = 17680000;
  const toBlock = 18110000;
  const events = await contract.queryFilter("Deposit", genesisBlock, "latest");

  let txData = [];
  let senders = [];

  let tokenPrice = await getNativeTokenPrice();
  let totalVolume = 0;
  let volume = 0;
  
  for(var i=0; i < events.length; i++){
      let event = events[i];
      let sender = event.args[0];
      let amount = Number(event.args[1]);
      let amountETH = parseFloat(ethers.formatEther(amount.toString()));
      let amountUSD = amountETH * tokenPrice;

      let data = {
          "amount": amount,
          "amountETH": amountETH,
          "amountUSD": amountUSD,
          "sender": sender,
          "txHash": event.transactionHash,
          "blockNumber": event.blockNumber,
          "id": sender
      };

      totalVolume += amount;

      if(event.blockNumber <= fromBlock){
        continue
      }

      if(toBlock != "latest"){
        if(toBlock < event.blockNumber){
            continue;
        }
      }

      volume += amount;

      if(!senders.includes(sender)){
          senders.push(event.args[0])
          txData.push({
              id: sender,
              sender: sender,
              amountETH: amount,
              amountUSD: amountUSD,
              transactions: [data],
              rank: 0
          });
      }else{
          let idx = senders.indexOf(sender);
          txData[idx].transactions.push(data)
          txData[idx].amountETH += amount;
          txData[idx].amountUSD = (
              parseFloat(
                ethers.formatEther(BigInt(
                    Number(txData[idx].amountETH.toString())
                ))
              ) * tokenPrice
          );
      }
  }

  txData.sort((a,b) => Number(b.amountETH - a.amountETH));

  for(var j=0; j < txData.length; j++){
    txData[j].rank = j+1;
    txData[j].transactions.sort((a,b) => Number(b.blockNumber - a.blockNumber));
  }

  let out = {
    txData: txData,
    totalVolumeETH: totalVolume,
    totalVolumeUSD: parseFloat(
        ethers.formatEther(BigInt(totalVolume).toString())
    ) * tokenPrice,
    volumeETH: volume,
    volumeUSD: parseFloat(
        ethers.formatEther(BigInt(volume).toString())
    ) * tokenPrice
  }

  return out;
}

async function getDepositData() {
    let nullData = {
        txData: [],
        totalVolumeETH: 0,
        totalVolumeUSD: 0.0,
        volumeETH: 0,
        volumeUSD: 0.0,
        loaded: false
    }

    const updateData = async () => {
        let _data = await _getDepositData();

        if(_data.txData){
            const { data, error } = await supabase
            .from('chainData')
            .insert([
                { data: _data},
            ])
            .select()
        }

        return _data;
    }

    const getData = async () => {
        let { data: chainData, error } = await supabase
        .from('chainData')
        .select('*')

        if(error == null){
            return chainData;
        }
        return [];
    }

    let chainData = await getData();

    if(chainData.length < 1){
        nullData = await updateData();
        nullData["loaded"] = true;
    }else{
        var ONE_HOUR = 60 * 60 * 1000;
        var THIRTY_MINUTES = Math.ceil(ONE_HOUR/2);
        let latestData = chainData[chainData.length-1];
        let myDate = new Date(latestData["created_at"]);
        nullData = latestData.data;
        
        if(((new Date) - myDate) > THIRTY_MINUTES){
            nullData = await updateData();
        }
        nullData["loaded"] = true;
    }
    

    return nullData;
}

export {
    getDepositData,
    provider
}