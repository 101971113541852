import { ConnectButton } from '@rainbow-me/rainbowkit';
import clsx from 'clsx';
import { ethers } from "ethers";

function Wallet({ myResult }) {
    return (
        <>
            <div className="mt-2 inline-block w-full py-2 align-middle border bg-neutral-900 sm:px-6 lg:px-8 border-[#2ba8a4]/80 overflow-y-hidden overflow-x-auto">
                <table className="min-w-full table-fixed">
                    <thead className='space-grotesk collapse'>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left font-bold text-gray-200 sm:pl-0"
                            >
                                Rank
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left font-bold text-gray-200"
                            >
                                Address
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left font-bold text-gray-200"
                            >
                                Amount ETH
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left font-bold text-gray-200"
                            >
                                Amount USD
                            </th>
                        </tr>
                    </thead>
                    <tbody className='space-grotesk'>
                        <tr
                            className={clsx(
                                'cursor-pointer hover:bg-[#36d2cd]/40 font-bold w-full text-gray-300 hover:text-white'
                            )}
                        >
                            <td className="py-4 pl-4 pr-3 text-sm font-bold whitespace-nowrap">
                                #{myResult?.rank ?? 0}
                            </td>
                            <td className="px-3 py-4 text-sm whitespace-nowrap">
                                <ConnectButton 
                                    showBalance={false} 
                                    accountStatus="address"
                                    chainStatus="none" 
                                />
                            </td>
                            <td className="px-3 py-4 text-sm whitespace-nowrap">
                                {parseFloat(
                                    ethers.formatEther(myResult?.amountETH?.toString() ?? "0")
                                ).toFixed(1)} ETH
                            </td>
                            <td className="px-3 py-4 text-sm whitespace-nowrap">
                                ${myResult?.amountUSD?.toFixed(1) ?? 0.0} USD
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export {
    Wallet
}